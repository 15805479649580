<template>
  <div class="py-5" :style="`background: ${index % 2 != 0 ? '#fff' : '#e8f1f8'}`">
    <div class="container">
      <div v-html="data.pl6_header" class="mb-4"></div>
      <div v-if="data.childrens">
        <div class="row align-items-center">
          <template v-for="item in data.childrens">
            <div class="col-lg-6" :key="item.pl6c_id">
              <div class="card shadow">
                <div class="card-body" v-html="item.pl6c_content"></div>
              </div>
            </div>
          </template>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  components: {},
  data() {
    return {};
  },
  props: {
    data: {
      type: Object,
      default: () => {},
    },
    index: {
      type: Number,
      default: 0,
    },
  },
  mounted() {},
};
</script>
